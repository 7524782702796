<template lang="html">
  <div class="center-screen">
    <b-row>
      <b-col cols="12">
        <b-img
        @click="$router.push({path: '/'})"
        src="@/assets/images/logo/logo-brandme1.png"
        class="cursor-pointer"
        />
      </b-col>
      <b-col cols="12" class="mt-2 w-150">
        <b-card>
          <b-card-title class="title-card text-left text-montserrat">{{ $t('titlePreferenceEmail') }}</b-card-title>
          
          <b-card-body class="pl-0 pr-0">
            <p class="text-left body-text text-montserrat">{{ $t('textUnsubscribe') }}</p>

            <b-img
            fluid
            src="@/assets/images/profile/unsubscribe.png"
            />
            <h4 class="mt-2 text-montserrat">{{email}}</h4>
            <div class="d-flex align-items-center justify-content-center">
              <b-button :disabled="disabledBtn" @click="unsubscribe()" variant="primary" class="button mt-2 mb-1">{{$t('btnUnsubscribe')}}</b-button>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12" class="d-flex align-items-center justify-content-space-evenly">
        <div class="text-footer text-montserrat">
          <p>{{$t('notEmail')}}</p>
          <p>{{$t('followUs')}}</p>
        </div>
        <div>
          <b-link
            class="ml-25 "
            href="https://twitter.com/brandme"
            target="_blank"
          >
            <span
              class="icomoon-Twitter-1 twitter text-footer"
              style="margin: 0 4px; font-size:1.3em;"></span>
          </b-link>
          <b-link
            class="ml-25"
            href="https://www.facebook.com/brandme"
            target="_blank"
          >
            <span
              class="icomoon-Facebook-2 facebook text-footer"
              style="margin: 0 4px; font-size:1.3em;"></span>
          </b-link>
          <b-link
            class="ml-25"
            href="https://www.instagram.com/brandme/"
            target="_blank"
          >
            <span
            class="icomoon-instagram1 instagram text-footer"
            style="margin: 0 4px; font-size:1.3em;"></span>

          </b-link>
        </div>
      </b-col>
    </b-row>

    <b-modal
    id="modal-sm"
    size="sm"
    title="Small Modal"
    ref="modal-success"
    v-on:hide="redirect"
    centered>
      <template #modal-header="{}">
        <h5 class="text-montserrat">{{$t('headerUnsubscribe')}}</h5>
      </template>
      <p class="text-montserrat">{{$t('bodyUnsbscribe')}}</p>
      <template #modal-footer="{ ok }">
        <b-button variant="success" @click="ok()">
          OK
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
 
<script lang="js">
import {
  BImg, BButton, BRow, BCol, BCard, BCardTitle, BCardBody, BModal, BLink
} from 'bootstrap-vue'
import profile_services from '@/services/settings'
export default  {
  name: 'Unsubscribe',
  props: {},
  components: {
    BImg,
    BButton,
    BRow,
    BCard,
    BCardTitle,
    BCardBody,
    BLink,
    BModal,
    BCol
  },
  mounted() {
    this.email = this.$route.query.email;
  },
  data() {
    return {
      email: '',
      disabledBtn: false,
    }
  },
  methods: {
    unsubscribe() {
      this.disabledBtn = true;
      profile_services.unsubscribe(this.email)
        .then(() => {
          this.$refs["modal-success"].toggle("#toggle-btn");
        })
        .catch(() => {
          this.$refs["modal-success"].toggle("#toggle-btn");
        });
    },
    redirect() {
      this.$router.push({path: '/'});
    }
  },
  computed: {
  }
}
</script>
<style>
  .text-left {
    text-align: left;
  }
  .center-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
  }

  .justify-content-space-evenly {
    justify-content: space-evenly;
  }

  .w-150 {
    width: 150px;
  }

  .text-montserrat {
    font-family: Montserrat;
    color: #6E6B7B;
  }
  .text-footer {
    color: #6E6B7B;
  }

  .title-card {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #5E5873;
  }

  .body-text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
  }
</style>