import config from '@/services/config'
import axios from './axios_interceptors'
import {tokenExpired} from '@/auth/utils'
export default {
  changeAvatar(image) {
    const data = new FormData()
    data.append('profile_image', image)
    return axios.put(`${config.urlCore}/api/v1/accounts/profile/image/`,
      data,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      });
  },
  unsubscribe(email) {
    const data = new FormData()
    data.append('email', email)

    return axios.get(`${config.urlCore}/api/v1/accounts/unsubscribe/?to_email=${email}`,
      data).then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  }
}
